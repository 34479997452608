
import { Component, Vue, Prop } from "vue-property-decorator";

enum TagType {
    brand = "brand",
    error = "error",
    success = "success",
}

@Component
export default class Tag extends Vue {
    @Prop({ default: "brand" }) type!: TagType;

    get colorClasses() {
        switch (this.type) {
            case TagType.error:
                return "bg-red text-red bg-opacity-25";
                break;
            case TagType.success:
                return "bg-[#C6F3E9] text-green";
                break;
            default:
                return "text-primary-500 bg-primary-300";
                break;
        }
    }
}
